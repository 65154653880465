export const SnapLogo = ({ color, size }: { color: string; size: number }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="23.64 13.64 497.57 545.45"
  >
    {' '}
    <g transform="scale(8.363636363636363) translate(8, 8)">
      {' '}
      <defs id="SvgjsDefs5688">
        <linearGradient id="SvgjsLinearGradient5693">
          <stop id="SvgjsStop5694" stop-color="#00ff8f" offset="0"></stop>
          <stop id="SvgjsStop5695" stop-color="#00a1ff" offset="1"></stop>
        </linearGradient>
      </defs>
      <g
        id="SvgjsG5689"
        transform="matrix(3.126714449432998,0,0,3.126714449432998,-3.053633914664923,-21.435081270792313)"
        fill="url(#SvgjsLinearGradient5693)"
      >
        <path d="M7.9688 22.207 q-0.390625 0 -0.6787109375 -0.2880859375 t-0.28809 -0.68848 l0 -1.2695 q-1.71875 -0.25390625 -3.076171875 -1.220703125 t-2.1533 -2.4561 t-0.7959 -3.2568 q0 -0.556640625 0.078125 -1.11328125 q0.087890625 -0.419921875 0.390625 -0.64453125 q0.25390625 -0.17578125 0.5859375 -0.17578125 q0.46875 0 0.771484375 0.390625 q0.185546875 0.25390625 0.185546875 0.556640625 l-0.019531 0.17578 q-0.05859375 0.400390625 -0.05859375 0.810546875 q0 1.8359375 1.157226563 3.212890625 t2.9346 1.7578 l0 -1.123 q0 -0.400390625 0.2880859375 -0.68359375 t0.67871 -0.2832 q0.400390625 0 0.6884765625 0.283203125 t0.28809 0.68359 l0 1.123 q1.787109375 -0.380859375 2.944335938 -1.7578125 t1.1572 -3.2129 q0 -1.34765625 -0.6640625 -2.509765625 t-1.8457 -1.8652 q-0.478515625 -0.29296875 -0.478515625 -0.830078125 q0 -0.25390625 0.146484375 -0.48828125 q0.29296875 -0.478515625 0.830078125 -0.478515625 q0.244140625 0 0.48828125 0.126953125 q1.572265625 0.927734375 2.5 2.51953125 q0.95703125 1.66015625 0.95703125 3.525390625 q0 1.767578125 -0.7958984375 3.256835938 t-2.1582 2.4561 t-3.0811 1.2207 l0 1.2695 q0 0.400390625 -0.2880859375 0.6884765625 t-0.68848 0.28809 z"></path>
      </g>
    </g>
  </svg>
);
